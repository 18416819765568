export const AUTHENTICATION_STORAGE_KEYS: any = {
	apiToken: 'api_token',
	storedUser: 'stored_user',
};


export const AUTHENTICATION_HEADER_KEYS: any = {
	apiToken: 'Authorization'
};

export const InterceptorSkipAuthenticationHeader: string = 'skip-authentication-header-interceptor';
